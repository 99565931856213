import React from 'react';
import {motion} from "framer-motion";
import helpers from './helpers';

export const Resume = () => (
    <motion.div
      className="resume"
      initial="initial"
      animate="animate"
      exit="exit"
      variants={helpers.pageVariants}
    >
      <h2>
          Summary
          <a href="resume.pdf" download className="btn"><span>download</span></a>
      </h2>
      <ul>
          <li>Web developer experienced in producing personal and commercial web sites for a broad variety of applications</li>
          <li>Participated in as well as led all stages of development process adhering to industry standards & best practices</li>
          <li>Pursuing new opportunities intending to help organizations grow as well as advance personal skills</li>
      </ul>

      <h2>Skills</h2>
      <h3>Front-end</h3>
      HTML5 · CSS3/SCSS · JavaScript/ES6 · jQuery · React · Bootstrap · WCAG
      <h3>Back-end</h3>
      PHP · MySQL · Apache · WordPress · Joomla
      <h3>Workflow</h3>
      Git · Webpack · Grunt
      <h3>IDE & OS</h3>
      PhpStorm · Atom · Linux · Windows · Mac
      <h3>Graphics</h3>
      Photoshop · Illustrator · GIMP
      <h3>QA</h3>
      Manual testing · multi-platform testing · Bugzilla · xDebug

      <h2>Experience</h2>
      <h3>Freelancer / January 2018 - present</h3>
      <ul>
          <li>Presented to clients viable and cost-efficient technical requirements for their projects</li>
          <li>Envisioned websites by mocking up the designs of all pages ensuring clients’ confidence in upcoming websites’ outlook, feel, and functionality</li>
          <li>Installed, configured, and performance-optimized CMS and third-party plugins to suit specific client needs</li>
          <li>Developed either completely custom themes or adapted pre-made third-party themes satisfying various budgets and preferences</li>
          <li>Delivered complete codebase tested on multiple platforms and launched websites into live environments within the initially negotiated timeframes</li>
          <li>Performed ongoing development to maintain an up-to-date website core & plugins while implementing the latest security standards and bug-free codebase</li>
      </ul>

      <h3>Intern at Ogritech Web Development / March 2017 - January 2018</h3>
      <ul>
          <li>Turned website designs into working front-end solutions providing usable interface for people with outdated browsers such as IE10 and IE11 as well as mobile users</li>
          <li>Contributed ideas of useful website features followed by their design and code implementation fulfilling company’s desired goals and functionality</li>
          <li>Steered the redesign and contributed to the development of a musical web app forking its simple third-party karaoke plugin to achieve a guitar centered application for novice, learning, and professional players</li>
          <li>Functionally advanced the back-end interface of the musical web app allowing a smoother experience for the site admin to input song information and guitar centered karaoke timings efficiently and less time consuming</li>
      </ul>

      <h2>Education</h2>
      <h3>Wes Bos courses / 2021, 2022</h3>
      <ul>
          <li>
              ES6 for Everyone
          </li>
          <li>
              React For Beginners
          </li>
      </ul>

      <h3>React Academy / February 2020</h3>
      <ul>
          <li>3 day bootcamp on React.js</li>
          <li>Covered JSX, virtual DOM/reconciliation, components & their lifecycles, events, hooks</li>
      </ul>

      <h3>LinkedIn Learning / April 2018 - April 2019</h3>
      <ul>
          <li>Improve Your JavaScript Language Skills (5 courses)</li>
          <li>Become a Junior WordPress Developer (15 courses)</li>
          <li>WordPress: Developing Secure Sites</li>
          <li>Motion Design with CSS</li>
          <li>SEO Foundations</li>
          <li>WordPress: SEO</li>
          <li>Analyzing Your Website to Improve SEO</li>
          <li>Advanced SEO: Developing an SEO-Friendly Website</li>
          <li>UX Foundations: Accessibility</li>
      </ul>

      <h3>Lynda.com / November 2016</h3>
      <ul>
          <li>Programming Foundations: Software QA</li>
          <li>Project Management Foundations: Quality</li>
          <li>Advanced PHP: Debugging Techniques</li>
          <li>Programming Foundations: Test-Driven Development</li>
      </ul>
    </motion.div>
)

export default Resume;
